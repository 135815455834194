import { helper as buildHelper } from '@ember/component/helper';
import config from 'mewe/config';
import CurrentUserStore from 'mewe/stores/current-user-store';

function getPrimaryEmail() {
  const primaryEmail = CurrentUserStore.getState().get('primaryEmail');

  if (!primaryEmail) return false;

  return (
    primaryEmail.indexOf('@sgrouples.com') !== -1 ||
    primaryEmail.indexOf('@mewe.com') !== -1 ||
    primaryEmail.indexOf('@fakemail.groupl.es') !== -1 ||
    primaryEmail.indexOf('@groupl.es') !== -1 ||
    primaryEmail === 'mewenews@gmail.com'
  );
}

export function isMeWeTeam() {
  return config.environment === 'local' || getPrimaryEmail();
}

export default buildHelper(isMeWeTeam);
