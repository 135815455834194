import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import Session from 'mewe/shared/session';
import { action } from '@ember/object';
export default class MwSettingsApps extends Component {

  @tracked isLoading = false;

  @action
  async approveRequest(appId) {
    this.isLoading = true;
    try {
      await fetch(`/api/v2/user/applications/pending/${appId}`, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': Session.getCsrfToken(),
        },
      });
      this.args.refreshModel?.();
    } catch (error) {
      console.error('Failed to approve app request:', error);
    } finally {
      this.isLoading = false;
    }
  }

  @action
  async rejectRequest(appId) {
    this.isLoading = true;
    try {
      await fetch(`/api/v2/user/applications/pending/${appId}`, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': Session.getCsrfToken(),
        },
      });
      this.args.refreshModel?.();
    } catch (error) {
      console.error('Failed to reject app request:', error);
    } finally {
      this.isLoading = false;
    }
  }

  @action
  async revokeSession(sessionId) {
    this.isLoading = true;
    try {
      await fetch(`/api/v2/user/applications/sessions/${sessionId}`, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': Session.getCsrfToken(),
        },
      });
      this.args.refreshModel?.();
    } catch (error) {
      console.error('Failed to revoke session:', error);
    } finally {
      this.isLoading = false;
    }
  }
}
