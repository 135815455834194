import { computed, get } from '@ember/object';
import { isEmpty } from 'lodash';
import Model from 'mewe/utils/store-utils/model/model.js';
import NotificationModel from './notification-model';
import { attr, belongsTo } from 'mewe/utils/store-utils/model/attribute';
import { modelProfileId } from 'mewe/stores/models/mixins/model-profile-id';
import { convertHandleToObject } from 'mewe/helpers/handle-html';
import { fromJavaLocale } from 'mewe/utils/datetime-utils';
import { createDeferred } from 'mewe/shared/utils';
import { maxFavorites } from 'mewe/constants';

var model = Model.extend(modelProfileId, {
  id: attr('string'),
  contactInviteId: attr('string'), // old filed still used tpo support /i/contactInviteId
  distinctId: attr('string'), // separate id used for mixpanel to avoid exposing user's actual id to 3rd party
  dsnp: attr('boolean'), // returned in AccountApi.getCurrentUser response
  msaId: attr('string'),
  dsnpHandle: attr('string'),
  dsnpDetails: attr('object'), // returned in AccountApi.login request
  publicLinkId: attr('string'), // aka handle, new field to use
  public: attr('boolean'),
  descriptionPlain: attr('string'),
  firstName: attr('string'),
  fprint: attr('string'),
  lastName: attr('string'),
  _links: attr('object'),
  profileImageId: attr('string'),
  registered: attr('boolean'),
  language: attr('boolean'),
  primaryEmail: attr('string'),
  primaryPhoneNumber: attr('string'),
  defaultLanguage: attr('string'),
  invitationsCount: 2,
  timezone: attr('string'), // set to browser tz on each fetch of /me/info in current-user-store handle
  locale: attr('string'),
  badges: attr('object'),
  featureHints: attr('array'),
  settings: attr('object', {
    defaultValue: {},
  }),
  jailInfo: attr('object'),
  lockInfo: attr('object'),
  canInviteMembers: attr('boolean'),
  limitReached: attr('boolean'),
  notifications: belongsTo(NotificationModel, {
    defaultValue: () => NotificationModel.create(),
  }),
  sendDMByEnter: attr('boolean'),
  allowChatMsgSound: attr('boolean'),
  allowNotifSound: attr('boolean'),
  autoOpenMiniChat: attr('boolean'),
  deferred: attr('object', {
    defaultValue: function () {
      return createDeferred();
    },
  }),
  favorites: attr('array', {
    defaultValue: [],
  }),
  isFavoriteLimitReached: computed('favorites.length', function () {
    return this.favorites.length >= maxFavorites;
  }),

  name: computed('_name', 'firstName', 'lastName', {
    get() {
      let name = '';

      if (typeof this._name !== 'undefined') {
        return this._name;
      }

      if (this.firstName && this.lastName) {
        name = `${this.firstName} ${this.lastName}`;
      }

      return name;
    },
    set(key, value) {
      this._name = value;
      return value;
    },
  }),

  handleDisplay: computed('publicLinkId', 'dsnpHandle', function () {
    let handle = {
      core: this.publicLinkId,
      suffix: '',
    };

    if (this.dsnpHandle) {
      handle = convertHandleToObject(this.dsnpHandle);
    }

    return handle;
  }),

  jsLocale: computed('locale', function () {
    return fromJavaLocale(this.locale);
  }),

  utcDiff: computed(function () {
    return new Date().getTimezoneOffset() / 60;
  }),

  toJson() {
    var fields = get(this.constructor, 'fields'),
      field,
      value,
      props = {};

    for (field in fields) {
      if (fields.hasOwnProperty(field)) {
        switch (field) {
          case 'promise':
          case 'notifications':
          case 'settings':
            break;
          default:
            value = this.get(field);
            if (!isEmpty(value)) props[field] = value;
            break;
        }
      }
    }

    return JSON.stringify(props);
  },
});

model.reopenClass({
  resourceName: 'user-current',
  aliases: {
    userId: 'id',
    userName: 'name',
  },
});

export default model;
